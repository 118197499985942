.Callback {
    width: calc(100% - 415px);
    min-width: 385px;
    max-width: 1200px;
    margin: 20px 15px 0px 15px;
  }
  
  .Callback-Loader {
    display: flex;
    align-items: center;
    justify-content: space-around;
    min-height: 375px;
  }

  /* 2 column styling */
@media (min-width: 800px) {
    .Callback-Loader {
        border-left: solid 1px #e7eae8;   
    }
}