.Edit {
  width: calc(100% - 415px);
  min-width: 385px;
  max-width: 1200px;
  margin: 20px 15px 0px 15px;
}

.edit-header {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: #f4f7fb;
  border-radius: 5px;
  margin-bottom: 15px;
  padding: 15px;
}

.edit-sections {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 15px;
  padding: 15px;
  border: solid;
  border-color: #AAB8C2;
  border-radius: 5px;
}

.error-msg {
  position:absolute;
  max-width: 200px;
  color: #FFF;
  background: #0FBBEC;
  border-radius: 5px;
  padding: 0 10px;
  margin-left: 5px;
}