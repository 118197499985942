.Main {
  width: calc(100% - 415px);
  min-width: 385px;
  max-width: 1200px;
  margin: 20px 15px 0px 15px;
}
  
.MainLoader {
  display: flex;
  align-items: center;
  justify-content: space-around;
  min-height: 375px;
}

  /* 2 column styling */
@media (min-width: 800px) {
  .MainLoader {
    border-left: solid 1px #e7eae8;   
  }
}