.nav {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    height: 50px;
    width: 100%;
    background: rgba(255,255,255,0.95);
    text-align: center;
    text-transform: uppercase;
    font-family: 'Open Sans Condensed', sans-serif;
}

.nav ul {
    list-style: none;
    width: 100%;
    padding: 0px;
    margin: 0px;
}

.nav ul li {
    top: -3px;
    display: inline-block;
    position: relative;
    margin: 0 7px;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    transition: background-color .075s ease-in-out, color .075s ease-in-out, top .15s ease-in-out;
    color: #6b7770;
}

.nav ul li:hover {
    top: 0px;
    background-color: rgb(193, 202, 197);
    color: #fff;
}

.nav ul li.nav-active {
    background: #3887be;
    color: #fff;
}

.nav ul li a {
    position: relative;
    display: block;
    text-decoration: none;
    color: inherit;
    padding: 3px 1.5em 0.25em 1.5em;
    line-height: 43px;
    font-size: 14px;
    font-weight: 700;
}