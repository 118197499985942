.instruction {
  height: 25px;
  border-radius: 5px;
  transition: padding .15s ease-in-out;
}

.instruction:hover {
  background-color: #e7eae8;
}

.instruction:not(.react-draggable-dragging) {
  transform: translateZ(-1em)!important;
}

.instructions .id {
  display: inline-block;
  width: 30px;
  text-align: right;
  padding-right: 10px;
}

.instruction .id:hover{
  cursor: grab;
  margin-left: 2px;
}

.instruction .id:active{
  cursor: grabbing;
  content: "<>";
}

.instruction .id:active span {
	display: none;
}

.instruction .id:active:after {
	content: "..";
}

.instructions input.text {
  width: calc(100% - 100px);
}