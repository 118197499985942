.edit-main {
  margin-right: 15px;
}

.edit-main input {
  width: 180px;
}

.edit-main textarea {
  width: 300px;
}

.edit-main span {
  display: inline-block;
  width: 120px;
}