.edit-instructions {
    min-width: 200px;
    flex: 1;
}

.edit-instructions input, select {
  margin-right: 5px;
}

.edit-instructions button {
  color: white;
  border: none;
  border-radius: 5px;
  width: 30px;
  height: 20px;
}

.edit-instructions button.add {
  margin-left: 40px;
  background-color: green;
}

.edit-instructions button.remove {
  background-color: red;
}

.instructions {
  position: relative;
  transform-style: preserve-3d;
}