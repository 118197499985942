.Recipe {
  width: calc(100% - 415px);
  min-width: 385px;
  max-width: 1200px;
  margin: 20px 15px 0px 15px;
}

.Recipe-Header {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  text-align: left;
  background-color: #f4f7fb;
  border-radius: 5px;
  padding: 0px;
}

.Recipe-Header-Title {
  margin: 0px 0px 10px 30px;
  max-width: 470px;
}

.Recipe-Header-Title h2, h3, h4{
  margin: 0px;
}

.Recipe-Header-Title h3{
  font-size: 18px;
  font-weight: 400;
}

.Recipe-Header-Title h4{
  margin-left: 5px;
  font-size: 12px;
  font-weight: 200;
}

.Recipe-Header-Image{
  min-width: 385px;
  max-width: 500px;
}

.Recipe-Header-Image img{
  display: block;
  width: 100%;
  border-radius: 5px;
}

.Recipe-Sections {
  min-height: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  border: solid;
  border-color: #AAB8C2;
  border-radius: 5px;
  margin: 15px 0px;
}

.Recipe-Section-Header {
  margin: 20px 0px 5px 0px;
}

.Recipe-Section-Header h2 {
  display: inline-block;
  line-height: 32px;
  margin: 0px;
}

.Recipe-Section-Header img {
  vertical-align: sub;
}

.Recipe-Sections ul {
  list-style: none;
  margin: 0px;
  padding-left: 0;
}

.Recipe-Sections li {
  margin: 5px 0;
}

.Recipe-Sections li img {
  vertical-align: sub;
  margin-right: 5px;
}

.Recipe-List {
  min-width: 180px;
  max-width: 300px;
  margin: 0px 30px;
}

.Recipe-Main {
  min-width: 200px;
  flex: 1;
  margin: 0px 30px;
}