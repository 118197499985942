.Main {
    width: calc(100% - 415px);
    min-width: 385px;
    max-width: 1200px;
    margin: 20px 15px 0px 15px;
}
    
.MainList {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: flex-start;
    min-height: 375px;
}