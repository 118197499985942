.App {
  text-align: left;
}

.App-link {
  color: #61dafb;
}

.Container {
  background-color: #fff;
  color: rgb(107, 119, 112);
  padding-bottom: 20px;
  font-family: 'Open Sans', sans-serif;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
