
.CategoryList h4 {
  cursor:pointer;
  margin: 15px 10px 0px 10px;
  border-bottom: solid #c1cac5 1px;
  transition: padding .15s ease-in-out;
}

.hidden {
  display: none;
}

.CategoryList h4:hover {
  padding-left: 3px;
}

.CategoryList ul {
  list-style: none;
  margin: 0px;
  padding: 5px 10px;
}

.CategoryList ul li {
  padding: 2px 0;
}

.CategoryList ul li a {
  color: #0FBBEC;
  text-decoration: none;
  font-weight: 400;
  transition: padding .15s ease-in-out;
}

.CategoryList ul li a:hover {
  padding-left: 3px;
}