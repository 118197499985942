.header {
    text-align: center;
    font-family: 'Open Sans Condensed', sans-serif;
    font-weight: 700;
    text-transform: uppercase;
    padding: 70px 0 0 0;
    background: #fff;
    margin: 0 auto;
}
  
.header-bar{
    position: relative;
    text-align: center;
    border-top: solid 5px #e7eae8;
}

.header-float {
    background: #fff;
    position: relative;
    display: inline-block;
    padding: 0 1.5em 0 1.5em;
    top: -23px;
    height: 37px;
}

.header-float h1 {
    display: inline;
    font-size: 27px;
}

.header-float h1 a {
    text-decoration: none;
    color: rgb(107, 119, 112);
}

.header-float span {
    display: inline-block;
    padding-left: 5px;
    font-size: 27px;
    font-weight: 700;
    text-transform: uppercase;
    color: #c1cac5;
}

.header-hr {
    margin-top: -20px;
    border-bottom: solid 20px #3887be;
}