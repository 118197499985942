.Recipes {
  width: 325px;
  text-align: left;
  margin: 0 30px;
  font-size: 15px;
  font-weight: 700;
  margin-top: 20px;
}

.Recipes-Loader{
  display: flex;
  align-items: center;
  justify-content: space-around;
  Width: 325px;
}

.Recipes-Search {
  width: 311px;
  padding: 8px;
  border-radius: 5px;
  border: solid;
  border-color: rgba(215,219,217,.8);
  border-width: 1px;
  color: #6b7770;
  font-family: "Open Sans",sans-serif;
  margin: 1px;
}

.Recipes-Search:focus {
  outline: none;
  border-color: rgba(56,135,190,.3);
  border-width: 2px;
  margin: 0px;
}

.Recipes h3 {
  text-align: center;
  margin-bottom: 5px;
  color: rgb(107, 119, 112);
}