.footer {
    text-align: center;
    font-family: 'Open Sans Condensed', sans-serif;
    text-transform: uppercase;
    background-color: #e7eae8;
    height: 170px;
}

.footer-hr {
    border-bottom: solid 10px #c1c1c1;
}

ul.footer-contact {
    padding: 0px;
}
  
ul.footer-contact li {
    position: relative;
    display: inline-block;
    margin: 0 0.25em 0 0.25em;
    top: 0;
    transition: top .15s ease-in-out;
}

ul.footer-contact li a
{
    display: block;
    position: relative;
    background: #c1cac5 url('/static/images/icons-contact.png');
    width: 48px;
    height: 48px;
    text-indent: -9999px;
    border-radius: 6px;
    outline: 0;
    transition: background-color .2s ease-in-out;
}

ul.footer-contact li a.facebook { background-position: 0px 0px; }
ul.footer-contact li:hover a.facebook { background-color: #3C5A98; }

ul.footer-contact li a.twitter { background-position: -48px 0px; }
ul.footer-contact li:hover a.twitter { background-color: #2DAAE4; }

ul.footer-contact li a.rss { background-position: -96px 0px; }
ul.footer-contact li:hover a.rss { background-color: #F2600B; }

ul.footer-contact li a.dribbble { background-position: -144px 0px; }
ul.footer-contact li:hover a.dribbble { background-color: #C4376B; }

ul.footer-contact li a.linkedin { background-position: -192px 0px; }
ul.footer-contact li:hover a.linkedin { background-color: #006599; }

ul.footer-contact li a.tumblr { background-position: -240px 0px; }
ul.footer-contact li:hover a.tumblr { background-color: #31516A; }

ul.footer-contact li a.googleplus { background-position: -288px 0px; }
ul.footer-contact li:hover a.googleplus { background-color: #DA2713; }

ul.footer-contact li a.pinterest { background-position: -336px 0px; }
ul.footer-contact li:hover a.pinterest { background-color: #BD081C; }

ul.footer-contact li a.instagram { background-position: -384px 0px; }
ul.footer-contact li:hover a.instagram { background-color: #9A664F; }

ul.footer-contact li:hover
{
    top: -5px;
}

ul.footer-contact li:active
{
    top: 0;
}