.edit-ingredients{
    margin-right: 15px;
}

.edit-ingredients input {
  margin: 0 3px;
  height: 30px;
  padding: 0 3px;
}

.edit-ingredients input.amount {
  width: 40px;
}

.edit-ingredients input.whole {
  height: 34px;
  width: 50px;
  border: none;
  /* border-bottom: solid 1px lightgray; */
  padding: 0px;
  text-align: center;
  font-size: 27px;
}

.edit-ingredients div.fraction {
  display: inline-block;
}

.edit-ingredients div.fraction input {
  display: block;
  text-align: center;
  width: 20px;
  height: 16px;
  border: none;
  padding: 0px;
}

.edit-ingredients div.fraction input.numerator {
  border-bottom: solid 2px;
}

.edit-ingredients select.measurement {
  margin: 0px 5px;
  width: 60px;
}

.edit-ingredients button {
  color: white;
  border: none;
  border-radius: 5px;
  width: 30px;
  height: 20px;
}

.edit-ingredients button.add {
  margin-left: 3px;
  background-color: green;
}

.edit-ingredients button.remove {
  background-color: red;
}